<template>
  <!-- 同个弹窗页面实现父子页面切换的容器组件 -->
  <!-- 页面组件事件传递要求先传递给指定的容器事件moduleEvent，然后再分发出去 -->
  <div>
    <vxe-modal
      v-model="ModalVisible"
      :width="modalWidth"
      :height="currentModule.params.height"
      :show-zoom="true"
      resize
      :lock-view="false"
      :mask="false"
      :position="currentModule.params.position"
      @close="handleCloseModal"
      class="vxe-container"
      transfer
    >
      <div slot="title">
        <a-icon type="arrow-left" class="title-arrow" @click.stop="handleNavigateBack" v-if="navigateBackVisible" />
        <span>{{ currentModule.params.parentTitle ? currentModule.params.parentTitle : '' }}{{ currentModule.title }}</span>
      </div>
      <!-- 页面缓存，页面切换时根据需要使用activated（页面重新激活）与deactivated （页面退出） -->
      <!-- 可以控制页面返回时是否需要刷新页面 -->
      <keep-alive>
        <component
          :is="currentModule.component"
          :params="currentModule.params"
          @close="handleClose"
          @navigateTo="handleNavigateTo"
          @update="handleUpdate"
          @moduleEvent="handleModuleEvent"
          @toTrackVideo="handleToTrackVideo"
        ></component>
      </keep-alive>
    </vxe-modal>
  </div>
</template>
<script>
export default {
  name: 'Nav',
  props: {
    // 模块树对象
    moduleTreeObj: {
      type: Object,
      default: () => {}
    },
    // 初始参数
    initParams: {
      type: Object,
      default: () => {}
    },
    // 当前激活的模块Key
    activeModuleKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 当前模块
      currentModule: {
        params: {}
      },
      // 当前组件需要传入的参数,默认激活模块
      params: null,
      arrowLeftUrl: require('@/assets/images/arrow-left.png'),
      ModalVisible: false,
      // 返回页面要更新
      navigateBackUpdate: false,
      screenWidth: document.body.clientWidth
    }
  },
  computed: {
    navigateBackVisible() {
      if (this.currentModule && this.currentModule['key'] === this.activeModuleKey) {
        return false
      } else {
        return true
      }
    },
    modalWidth() {
      return this.screenWidth - this.initParams.position.left - 10
    }
  },
  mounted() {
    this.setCurrentModule(this.activeModuleKey, this.initParams)
    window.addEventListener('resize', () => {
      this.screenWidth = document.body.clientWidth
    })
  },
  methods: {
    // 指定获取模块
    getModule(moduleTreeObj, moduleKey) {
      if (Array.isArray(moduleTreeObj)) {
        // 如果是数组
        for (let k = 0; k < moduleTreeObj.length; k++) {
          return this.getModule(moduleTreeObj[k], moduleKey)
        }
      } else if (typeof moduleTreeObj === 'object') {
        // 如果是对象
        if (moduleTreeObj['key'] === moduleKey) {
          return moduleTreeObj
        } else {
          const childModuleTreeObj = moduleTreeObj['children']
          if (childModuleTreeObj && Array.isArray(childModuleTreeObj)) {
            return this.getModule(childModuleTreeObj, moduleKey)
          }
        }
      }
    },
    // 获取上一个模块
    getParentModule(moduleTreeObj, moduleKey, pModuleTreeObj) {
      if (!moduleTreeObj) {
        return null
      }
      if (this.currentModule.backTop) {
        return moduleTreeObj
      }
      if (Array.isArray(moduleTreeObj)) {
        // 如果是数组
        for (let k = 0; k < moduleTreeObj.length; k++) {
          return this.getParentModule(moduleTreeObj[k], moduleKey, pModuleTreeObj)
        }
      } else if (typeof moduleTreeObj === 'object') {
        // 如果是对象
        if (moduleTreeObj['key'] === moduleKey) {
          return pModuleTreeObj
        } else {
          const childModuleTreeObj = moduleTreeObj['children']
          if (childModuleTreeObj && Array.isArray(childModuleTreeObj)) {
            return this.getParentModule(childModuleTreeObj, moduleKey, moduleTreeObj)
          }
        }
      }
    },
    // 设置当前展示模块
    setCurrentModule(activeModuleKey, currentParams) {
      const currentModule = this.getModule(this.moduleTreeObj, activeModuleKey)
      if (currentModule && typeof currentModule === 'object') {
        console.log('找到组件配置', activeModuleKey)
        this.currentModule = currentModule
        // 当前组件参数
        this.currentModule.params = { ...this.initParams, ...currentParams }
        // 页面切换前进
        this.currentModule.params.navigateType = 'to'
        this.ModalVisible = this.currentModule.params.isShowModal
      } else {
        console.log('找不到组件配置', activeModuleKey)
        this.currentModule = {
          params: {}
        }
      }
      console.log('currentModule', currentModule)
    },
    handleClose() {
      console.log('handleClose')
    },
    // 返回上一级页面
    handleNavigateBack() {
      // 根据当前激活组件返回上一级
      this.currentModule = this.getParentModule(this.moduleTreeObj, this.currentModule['key'], null)
      // 标记当前页面为后退返回的
      this.currentModule.params.navigateType = 'back'
      // 判断页面返回时是否需要刷新页面数据
      if (this.navigateBackUpdate) {
        this.navigateBackUpdate = false
        this.currentModule.params.navigateBackUpdate = true
      } else {
        this.currentModule.params.navigateBackUpdate = false
      }
    },
    // 导航到新页面
    handleNavigateTo(activeModuleKey, currentParams) {
      this.setCurrentModule(activeModuleKey, currentParams)
    },
    handleToTrackVideo(e) {
      this.$emit('handleToTrackVideo', e)
    },
    handleCloseModal() {
      // 通知关闭Modal
      this.$emit('closeModal')
    },
    // 返回时通知是否需要刷新
    handleUpdate() {
      console.log('update for back')
      this.navigateBackUpdate = true
    },
    // 事件名称 + 事件参数
    handleModuleEvent(eventName, eventParams) {
      console.log('handleModuleEvent', eventName + eventParams)
      this.$emit(eventName, eventParams)
    }
  }
}
</script>
<style lang="less">
.title-arrow {
  margin-right: 30px;
  cursor: pointer;
  font-size: 20px;
}
.vxe-container {
  .vxe-modal--content {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
